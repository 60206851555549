.white-bg{
    background-color: #ffffff;
}
.dashboard-bg{
    background-color: #d3d3d333;
}
/* .nav-button-active-color{
    background: rgb(241, 239, 239);
} */
.nav-button-active-text-color{
    color: #d33d5f;
}
@media (max-width: 640px) {
    .check-class {
        display: none;
    }
}
