
ol.lst-kix_8kdpim9qcksn-2.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-2 0;
}
ol.lst-kix_k6vku0be8pt4-2.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-2 0;
}
.lst-kix_k6vku0be8pt4-6 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-6;
}
.lst-kix_k6vku0be8pt4-7 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-7;
}
ol.lst-kix_bdhh2yg1lde2-6.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-6 0;
}
.lst-kix_8kdpim9qcksn-0 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-0;
}
.lst-kix_bdhh2yg1lde2-5 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-5;
}
.lst-kix_8kdpim9qcksn-1 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-1;
}
.lst-kix_bdhh2yg1lde2-3 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-3;
}
.lst-kix_bdhh2yg1lde2-6 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-6;
}
ol.lst-kix_k6vku0be8pt4-0 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-0.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-0 0;
}
ol.lst-kix_bdhh2yg1lde2-7.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-7 0;
}
.lst-kix_k6vku0be8pt4-3 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-3;
}
.lst-kix_k6vku0be8pt4-4 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-4;
}
ol.lst-kix_bdhh2yg1lde2-1.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-1 0;
}
ol.lst-kix_k6vku0be8pt4-4 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-7.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-7 0;
}
.lst-kix_bdhh2yg1lde2-8 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-8;
}
ol.lst-kix_k6vku0be8pt4-3 {
  list-style-type: none;
}
.lst-kix_8kdpim9qcksn-3 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-3;
}
ol.lst-kix_k6vku0be8pt4-2 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-1 {
  list-style-type: none;
}
.lst-kix_bdhh2yg1lde2-2 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-2;
}
ol.lst-kix_k6vku0be8pt4-8 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-7 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-4.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-4 0;
}
ol.lst-kix_k6vku0be8pt4-6 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-5 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-8.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-8 0;
}
ol.lst-kix_8kdpim9qcksn-1.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-1 0;
}
ol.lst-kix_k6vku0be8pt4-5.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-5 0;
}
ol.lst-kix_bdhh2yg1lde2-0.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-0 0;
}
.lst-kix_bdhh2yg1lde2-1 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-1, lower-latin) ". ";
}
ol.lst-kix_bdhh2yg1lde2-2.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-2 0;
}
.lst-kix_bdhh2yg1lde2-2 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-2, lower-roman) ". ";
}
.lst-kix_8kdpim9qcksn-2 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-2, lower-roman) ". ";
}
.lst-kix_k6vku0be8pt4-1 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-1;
}
.lst-kix_bdhh2yg1lde2-0 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-0, decimal) ". ";
}
.lst-kix_bdhh2yg1lde2-8 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-8, lower-roman) ". ";
}
.lst-kix_bdhh2yg1lde2-7 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-7, lower-latin) ". ";
}
.lst-kix_k6vku0be8pt4-0 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-0;
}
.lst-kix_8kdpim9qcksn-1 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-1, lower-latin) ". ";
}
.lst-kix_8kdpim9qcksn-6 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-6;
}
.lst-kix_bdhh2yg1lde2-6 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-6, decimal) ". ";
}
.lst-kix_8kdpim9qcksn-0 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-0, decimal) ". ";
}
.lst-kix_bdhh2yg1lde2-3 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-3, decimal) ". ";
}
.lst-kix_bdhh2yg1lde2-5 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-5, lower-roman) ". ";
}
.lst-kix_bdhh2yg1lde2-4 > li:before {
  content: "" counter(lst-ctn-kix_bdhh2yg1lde2-4, lower-latin) ". ";
}
.lst-kix_8kdpim9qcksn-4 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-4;
}
.lst-kix_8kdpim9qcksn-7 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-7;
}
ol.lst-kix_bdhh2yg1lde2-8.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-8 0;
}
ol.lst-kix_bdhh2yg1lde2-7 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-8 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-5 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-6 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-3 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-4 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-1 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-6.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-6 0;
}
ol.lst-kix_bdhh2yg1lde2-2 {
  list-style-type: none;
}
ol.lst-kix_bdhh2yg1lde2-0 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-1 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-2 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-3 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-4 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-5 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-6.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-6 0;
}
ol.lst-kix_8kdpim9qcksn-6 {
  list-style-type: none;
}
ol.lst-kix_k6vku0be8pt4-3.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-3 0;
}
ol.lst-kix_8kdpim9qcksn-7 {
  list-style-type: none;
}
ol.lst-kix_8kdpim9qcksn-8 {
  list-style-type: none;
}
.lst-kix_bdhh2yg1lde2-0 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-0;
}
ol.lst-kix_8kdpim9qcksn-3.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-3 0;
}
ol.lst-kix_bdhh2yg1lde2-5.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-5 0;
}
ol.lst-kix_8kdpim9qcksn-0 {
  list-style-type: none;
}
.lst-kix_k6vku0be8pt4-8 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-8, lower-roman) ". ";
}
ol.lst-kix_k6vku0be8pt4-0.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-0 0;
}
.lst-kix_k6vku0be8pt4-7 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-7, lower-latin) ". ";
}
ol.lst-kix_k6vku0be8pt4-7.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-7 0;
}
.lst-kix_k6vku0be8pt4-5 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-5, lower-roman) ". ";
}
.lst-kix_k6vku0be8pt4-4 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-4, lower-latin) ". ";
}
.lst-kix_k6vku0be8pt4-6 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-6, decimal) ". ";
}
ol.lst-kix_8kdpim9qcksn-4.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-4 0;
}
ol.lst-kix_bdhh2yg1lde2-4.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-4 0;
}
.lst-kix_k6vku0be8pt4-2 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-2;
}
.lst-kix_k6vku0be8pt4-5 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-5;
}
ol.lst-kix_bdhh2yg1lde2-3.start {
  counter-reset: lst-ctn-kix_bdhh2yg1lde2-3 0;
}
ol.lst-kix_k6vku0be8pt4-1.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-1 0;
}
.lst-kix_k6vku0be8pt4-8 > li {
  counter-increment: lst-ctn-kix_k6vku0be8pt4-8;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
ol.lst-kix_8kdpim9qcksn-5.start {
  counter-reset: lst-ctn-kix_8kdpim9qcksn-5 0;
}
.lst-kix_bdhh2yg1lde2-1 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-1;
}
.lst-kix_bdhh2yg1lde2-4 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-4;
}
.lst-kix_k6vku0be8pt4-1 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-1, lower-latin) ". ";
}
.lst-kix_8kdpim9qcksn-3 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-3, decimal) ". ";
}
.lst-kix_8kdpim9qcksn-4 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-4, lower-latin) ". ";
}
.lst-kix_k6vku0be8pt4-0 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-0, decimal) ". ";
}
.lst-kix_k6vku0be8pt4-2 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-2, lower-roman) ". ";
}
.lst-kix_8kdpim9qcksn-2 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-2;
}
.lst-kix_k6vku0be8pt4-3 > li:before {
  content: "" counter(lst-ctn-kix_k6vku0be8pt4-3, decimal) ". ";
}
.lst-kix_8kdpim9qcksn-5 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-5, lower-roman) ". ";
}
.lst-kix_bdhh2yg1lde2-7 > li {
  counter-increment: lst-ctn-kix_bdhh2yg1lde2-7;
}
.lst-kix_8kdpim9qcksn-7 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-7, lower-latin) ". ";
}
.lst-kix_8kdpim9qcksn-8 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-8, lower-roman) ". ";
}
.lst-kix_8kdpim9qcksn-8 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-8;
}
ol.lst-kix_k6vku0be8pt4-8.start {
  counter-reset: lst-ctn-kix_k6vku0be8pt4-8 0;
}
.lst-kix_8kdpim9qcksn-5 > li {
  counter-increment: lst-ctn-kix_8kdpim9qcksn-5;
}
.lst-kix_8kdpim9qcksn-6 > li:before {
  content: "" counter(lst-ctn-kix_8kdpim9qcksn-6, decimal) ". ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c7 {
  margin-left: 54pt;
  padding-top: 7pt;
  padding-left: 0pt;
  padding-bottom: 36pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c9 {
  margin-left: 72pt;
  padding-top: 7pt;
  padding-left: 0pt;
  padding-bottom: 36pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c2 {
  margin-left: 36pt;
  padding-top: 7pt;
  padding-left: 0pt;
  padding-bottom: 36pt;
  line-height: 1.25;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  margin-left: 36pt;
  padding-top: 7pt;
  padding-left: 0pt;
  padding-bottom: 36pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c10 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.25;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c16 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.125;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c12 {
  color: #06192b;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 36pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  color: #06192b;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.c5 {
  color: #06192b;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Arial";
  font-style: normal;
}
.c14 {
  color: #06192b;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 27pt;
  font-family: "Arial";
  font-style: normal;
}
.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c8 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: italic;
}
.c11 {
  text-decoration-skip-ink: none;
  font-size: 12pt;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c15 {
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.c0 {
  font-size: 12pt;
  color: #06192b;
  font-weight: 700;
}
.c20 {
  color: #000000;
  font-weight: 400;
  font-size: 11pt;
}
.c17 {
  background-color: #ffffff;
  width: 100%;
  padding: 72pt 72pt 72pt 72pt;
}
.c6 {
  padding: 0;
  margin: 0;
}
.c18 {
  color: inherit;
  text-decoration: inherit;
}
.c3 {
  color: #06192b;
  font-size: 12pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
