.caption{
    display: none;
}
.carousel-img:hover + .caption{
    display: block;
}
.c-color{
    background-color: rgb(168 85 247);
    color: white;
    
}
.emoji-picker {
    position: absolute;
    bottom: 40px;
    right: -15px;
    height: 450px;
    margin-left: 35rem;
    margin-right: 1rem;
}

.loader {
    border-top-color: #3498db; /* Customize loader color if needed */
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }


  .vl {
    border-left: 1.5px solid rgb(175, 175, 175);
    height: 140px
  }
  
  .css-1fdsijx-ValueContainer{
    width: 46.5rem !important;
  }  

  .css-qbdosj-Input input:focus{

    --tw-ring-color: none !important;
  }

  #map{
    display: none;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    padding: 3px 3px;
    position: absolute;
    top: -33px;
    z-index: 5;
    left: 0;
    font-size: 16px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.disable .rdrDayNumber{
  background-color: rgb(241, 35, 35) !important;
}
.disable .rdrDayNumber span{
  color: black !important;
  
}




