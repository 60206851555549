.form{
    background-color: #6750a414;
}
.button{
    background-color: #EA446A;
    
}
.button-hover{
    background-color: #d33d5f;
}
.text-color{
    color:#EA446A;
    
}
.text-color-hover{
    color: #EA446A;
}